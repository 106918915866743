import { useEffect, useState } from 'react'
import { Formik, useMField } from '@mprise/react-ui'
import { PhaseEntryForm } from '../../routes/phase/Home'
import { ScrapEntryForm } from '../../routes/scrap/Home'
import { TransferEntryForm } from '../../routes/transfer/transfer-job/Home'
import { SelectJobInventoryDetailDialog } from '../dialog/SelectJobInventoryDetailDialog'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { defined, fail } from '../typescript'
import { useHistory } from '../use-history'
import { JobPickEntryForm } from '../../routes/tasks/job-pick/Home'
import { useLazyQuery } from '@apollo/client'
import { JOB_INVENTORY_BY_FILTER } from '../../gql/query/jobInventories/jobInventoryByFilter'
import { Maybe } from '../enums'
import { JobInventoryDetail } from '../interfaces'

export type EntryFormType = TransferEntryForm | ScrapEntryForm | PhaseEntryForm

export const FieldJobInventoryDetail = ({
  title,
  includeCarrierCodes = false,
  itemConstraint,
  quantityPerUnitOfMeasure,
  unitOfMeasureCode,
  defaultQtyZeroOnPrefill = false,
}: {
  title: string
  includeCarrierCodes?: boolean
  itemConstraint?: Maybe<{ id: string; name?: Maybe<string> }>
  quantityPerUnitOfMeasure?: number
  unitOfMeasureCode?: string
  defaultQtyZeroOnPrefill?: boolean
}) => {
  const f = useMField()
  const fc = Formik.useFormikContext<EntryFormType>()
  const h = useHistory()
  const { company: currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id ? +currentCompany.id : h.push('/')

  const jobId = fc.values.job?.id
  const fromPositionId = fc.values.fromPosition?.id
  const workItemId = fc.values.workItemId

  const hasSelectedJob = !!jobId
  const hasSelectedFromPosition = !!fromPositionId
  const hasSelectedJid = !!fc.values.jobInventoryDetail

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useLazyQuery(JOB_INVENTORY_BY_FILTER)

  useEffect(() => {
    if (hasSelectedJid) {
      return
    }
    const itemId = (fc.values.job as JobPickEntryForm['job'])?.item?.id
    if (itemConstraint && itemId && itemConstraint.id !== itemId) {
      return
    }
    if ((hasSelectedJob && !hasSelectedFromPosition) || (hasSelectedFromPosition && !hasSelectedJob)) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: +companyId,
            jobId: hasSelectedFromPosition ? null : +jobId!,
            positionId: hasSelectedJob ? null : +fromPositionId!,
            includeCarrierCodes: includeCarrierCodes,
            excludedWorkItemId: workItemId ? +workItemId : null,
          },
        },
        fetchPolicy: 'no-cache',
      })
    }
  }, [jobId, fromPositionId])

  const jobInventoryDetails = jobInventoryDetailsQuery.data?.jobInventoryDetails?.filter(defined) ?? []

  useEffect(() => {
    if (jobInventoryDetailsQuery.data) {
      // Open select jobInventory Dialog if multiple or no jid
      if (jobInventoryDetails.length !== 1 && (hasSelectedJob || hasSelectedFromPosition)) {
        if (jobInventoryDetails.length === 0) {
          f.onChange?.(null)
        }
        setOpen(true)
      } else if (jobInventoryDetails.length === 1) {
        // Set jobInventoryDetail if only 1 jid found
        const jid = jobInventoryDetails[0] as JobInventoryDetail
        f.onChange?.(jid)
      }
    }
  }, [jobInventoryDetailsQuery])

  useEffect(() => {
    const jid = fc.values.jobInventoryDetail
    if (jid && jid.job && jid.position) {
      // prefill job and fromPosition when jobInventory is selected
      prefillForm(fc, jid, defaultQtyZeroOnPrefill, unitOfMeasureCode, quantityPerUnitOfMeasure)
    }
  }, [fc.values.jobInventoryDetail])

  return (
    <>
      {jobInventoryDetailsQuery.data && jobInventoryDetails.length !== 1 && (
        <SelectJobInventoryDetailDialog
          open={open}
          jobInventoryDetails={jobInventoryDetails}
          includeCarrierCodes={includeCarrierCodes}
          onClose={handleClose}
          onSave={(jobInventoryDetail: JobInventoryDetail) => {
            f.onChange?.(jobInventoryDetail)
            setOpen(false)
          }}
          title={title}
        />
      )}
    </>
  )
}

function prefillForm(
  fc: Formik.FormikContextType<EntryFormType>,
  jid: JobInventoryDetail,
  defaultQtyZero: boolean,
  unitOfMeasureCode?: string,
  quantityPerUnitOfMeasure?: number,
) {
  let quantity

  if (unitOfMeasureCode && unitOfMeasureCode !== jid.unitOfMeasure) {
    if (unitOfMeasureCode === 'Containers') {
      quantity = jid.remainingQuantity / (quantityPerUnitOfMeasure || 1)
    } else {
      quantity = jid.remainingQuantity * (quantityPerUnitOfMeasure || 1)
    }
  } else if (defaultQtyZero) {
    quantity = 0
  } else {
    quantity = jid.remainingQuantity
  }

  fc.setValues(current => ({
    ...current,
    job: {
      id: jid.job.id!,
      name: jid.job.name!,
      code: jid.job.code!,
    },
    fromPosition: {
      id: jid.position.id!,
      name: jid.position.name!,
      code: jid.position.code!,
    },
    originalQuantity: jid.remainingQuantity,
    quantity: quantity,
  }))
}
