import { gql } from '@apollo/client'

export const JOBS_BY_FILTER = gql`
  query jobsByFilter($filter: JobFilter, $limit: Int, $offset: Int) {
    jobs(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
        item {
          id
          name
        }
      }
      total
    }
  }
`
