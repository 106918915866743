import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Formik, MAvatar, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { TransferEntryForm } from '../../routes/transfer/transfer-job/Home'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined } from '../typescript'
import { DialogWithBackButton } from '../../components/Dialog'
import { POSITIONS_BY_FILTER } from '../../gql/positions'
import { Maybe } from '../enums'

export type Position = {
  id: string
  name?: Maybe<string>
  code?: Maybe<string>
}

const toSelectItem = ToMSelectItem<Position>({
  id: x => x.id,
  icon: () => <MAvatar.Icon.Position />,
  primary: x => x.code ?? '',
})

export const SelectPositionDialog = ({
  title,
  open,
  shouldFilterOrigin = true,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  shouldFilterOrigin?: boolean
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const fc = Formik.useFormikContext<TransferEntryForm>()
  const { company } = useAppSettingsContext()
  const companyId = company?.id

  const [positionsByFilter, { data, loading, error, fetchMore, refetch }] = useLazyQuery(POSITIONS_BY_FILTER)

  useEffect(() => {
    if (open) {
      positionsByFilter({
        variables: {
          filter: {
            companyId: +companyId!,
            ...(debouncedSearch && { searchString: debouncedSearch }),
            removed: false,
          },
          limit: 20,
          offset: 0,
        },
      })
    }
  }, [open, debouncedSearch, positionsByFilter, companyId])

  const handleSelect = async (item: MSelectItem<Position> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  // Filter out the position of origin for ad hoc (non-workitem) transfers
  const positionOrigin = fc.values.jobInventoryDetail?.position?.code

  const positions =
    data?.positions?.page
      ?.filter(defined)
      .filter(
        (position: any) => fc.values.isWorkItemTransfer || !shouldFilterOrigin || position.code !== positionOrigin,
      )
      .map(toSelectItem) ?? null


  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <PaginatedSearchAndSelect
        items={positions}
        search={search}
        value={null}
        loading={loading}
        onChange={handleSelect}
        fetchMore={fetchMore}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
        fetchMoreDataAccessor={(data: any) => data.positions?.page}
      />
    </DialogWithBackButton>
  )
}
